import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import React, { useState} from 'react';
import axios from "axios";
import swal from 'sweetalert';

const role = localStorage.getItem('role');

 const AddCustomer = () =>{
  const [file, setFile] = useState()

  function handleChange(event) {
    console.log(event.target.files[0])
    setFile(event.target.files[0])
  }
  
  function handleSubmit(event) {
    event.preventDefault()
    const url = 'http://bpodistributions.click:3010/uploads/uploadcustomers';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'accessToken': localStorage.getItem('accessToken')
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
     
        swal("Success", "Customers List Uploaded", "success", {
          buttons: false,
          timer: 2000,
        })
      
      // localStorage.setItem('customers', JSON.stringify(response.data));
    });

  }

  function handleSubmitkawasan(event) {
    event.preventDefault()
    const url = 'http://bpodistributions.click:3010/uploads/uploadkawasans';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'accessToken': localStorage.getItem('accessToken')
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
     
        swal("Success", "Kawasan List Uploaded", "success", {
          buttons: false,
          timer: 2000,
        })
      
      // localStorage.setItem('customers', JSON.stringify(response.data));
    });

  }

  function handleSubmitBills(event) {
    event.preventDefault()
    const url = 'http://bpodistributions.click:3010/uploads/upload_bills';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'accessToken': localStorage.getItem('accessToken')
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
     
        swal("Success", "Bills Uploaded", "success", {
          buttons: false,
          timer: 2000,
        })
      
      localStorage.setItem('customers', JSON.stringify(response.data));
    });

  }

  function handleSubmitOutstandingBills(event) {
    event.preventDefault()
    const url = 'http://bpodistributions.click:3010/uploads/upload_outstanding_bills';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'accessToken': localStorage.getItem('accessToken')
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
     
        swal("Success", "Outstanding Bills Uploaded", "success", {
          buttons: false,
          timer: 2000,
        })
      
      localStorage.setItem('customers', JSON.stringify(response.data));
    });

  }
  function handleSubmitFollowup(event) {
    event.preventDefault()
    const url = 'http://bpodistributions.click:3010/uploads/upload_followup';
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileName', file.name);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'accessToken': localStorage.getItem('accessToken')
      },
    };
    axios.post(url, formData, config).then((response) => {
      console.log(response.data);
     
        swal("Success", "Followups Uploaded", "success", {
          buttons: false,
          timer: 2000,
        })
      
      localStorage.setItem('customers', JSON.stringify(response.data));
    });

  }
    return(
        <>
            <Navbar />
            <div className="row">
                <Sidebar />
                
            </div> 
            {role === '1' &&
              <>
                {/* <main className="main-content position-relative h-100 border-radius-md contained ">
                  <div className="container-fluid g-3 ">
                  <h4 className="colorfix sizefix mx-2">Add Customers</h4>
                      <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                          <div className="container-fluid" style={{backgroundColor:"white"}}>
                              <div className="container-fluid px-0 ">
                                  <div className="col-12">
                                      <div className="container-fluid pb-4 pt-3 g-3">
                                          <div className="row g-0">
                                            <form onSubmit={handleSubmit}>
                                                <input className='col-6' type="file" onChange={handleChange}/>
                                                <button className='col-2' type="submit">Upload</button>
                                            </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </main> */}

                {/* upload kawasan */}
                {/* <main className="main-content position-relative h-100 border-radius-md contained ">
                  <div className="container-fluid g-3 ">
                  <h4 className="colorfix sizefix mx-2">Add Kawasan</h4>
                      <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                          <div className="container-fluid" style={{backgroundColor:"white"}}>
                              <div className="container-fluid px-0 ">
                                  <div className="col-12">
                                      <div className="container-fluid pb-4 pt-3 g-3">
                                          <div className="row g-0">
                                            <form onSubmit={handleSubmitkawasan}>
                                                <input className='col-6' type="file" onChange={handleChange}/>
                                                <button className='col-2' type="submit">Upload</button>
                                            </form>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                </main> */}
              </>
            }
            
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">Add Bills</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row g-0">
                                          <form onSubmit={handleSubmitBills}>
                                              <input className='col-6' type="file" onChange={handleChange}/>
                                              <button className='col-2' type="submit">Upload</button>
                                          </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">Add Outstanding Bills</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row g-0">
                                          <form onSubmit={handleSubmitOutstandingBills}>
                                              <input className='col-6' type="file" onChange={handleChange}/>
                                              <button className='col-2' type="submit">Upload</button>
                                          </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">Add Followups</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row g-0">
                                          <form onSubmit={handleSubmitFollowup}>
                                              <input className='col-6' type="file" onChange={handleChange}/>
                                              <button className='col-2' type="submit">Upload</button>
                                          </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    )
}

export default AddCustomer ;