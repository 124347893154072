import Navbar from "../componenets/Navbar";
import Sidebar from "../componenets/Sidebar";
import React, { useState, useEffect}  from 'react';
import TotalSentMessages from "../admin/components/TotalMessages";
import AllcustomersTable from "../admin/components/AllcustomersTable";
import { useNavigate } from "react-router-dom";

import swal from "sweetalert";
import axios from "axios";

import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';


import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import OutstandingBill from "./OutstandingBills";
import Followup from "./SendFollowup";

//test data for table
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: "#596CFF",
        fontWeight: "bold",
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
 
//end test table data

 function Messages() {
    const [customers, setCustomers] = useState([]);
    const [area, setArea] = useState([]);
    const [script, setScript] = useState();

    const navigate = useNavigate();

    const penjaja = [
      {id:50000,	kawasan: "PASAR HARIAN SELAYANG KUALA LUMPUR"},
{id:50002,	kawasan: "PASAR BERBANGUNAN UTC SENTUL"},
{id:51000,	kawasan: "PUSAT PENJAJA DANAU KOTA"},
{id:51200,	kawasan: "MEDAN SELERA SEK 4 WM"},
{id:51300,	kawasan: "PASAR WANGSA MAJU SEK. 2"},
{id:52000,	kawasan: "PUSAT PENJAJA JALAN DUTA"},
{id:52100,	kawasan: "PASAR BESAR TAMAN TUN DR ISMAIL"},
{id:53000,	kawasan: "PUSAT PENJAJA METRO PRIMA"},
{id:54000,	kawasan: "MEDAN SELERA PENJAJA TAMAN SETIAWANGSA"},
{id:54100,	kawasan: "PUSAT PENJAJA D'REJANG"},
{id:54200,	kawasan: "PUSAT PENJAJA JALAN USAHAWAN"},
{id:54300,	kawasan: "MEDAN SELERA D'TASIK"},
{id:54500,	kawasan: "PUSAT PENJAJA AIR PANAS"},
{id:54600,	kawasan: "PP SEMENTARA TAMAN KEJIRANAN AIR PANAS"},
{id:54700,	kawasan: "PASAR BERBANGUNAN KERAMAT MALL"},
{id:54800,	kawasan: "KIOSK LAMAN SEJAHTERA PPR SG BONUS"},
{id:55000,	kawasan: "PUSAT PENJAJA JALAN JEJAKA MALURI"},
{id:55100,	kawasan: "PASAR DAN PP JLN WIRAWATI KG PANDAN"},
{id:55200,	kawasan: "PUSAT PENJAJA TPCA, JALAN RAJA MUDA"},
{id:55400,	kawasan: "PUSAT SEMBELIHAN AYAM SEMENTARA"},
{id:55600,	kawasan: "MEDAN SELERA DAMAI JALAN BATU BATA"},
{id:60000,	kawasan: "PUSAT PENJAJA BANDAR SERI PERMAISURI"},
{id:61000,	kawasan: "ANJUNG SELERA, JALAN JUJUR"},
{id:61100,	kawasan: "PASAR BESAR CHERAS"},
{id:61200,	kawasan: "PASAR SG BESI"},
{id:61300,	kawasan: "PP PARK & RIDE SG BESI"},
{id:61400,	kawasan: "PUSAT KOMUNITI BANDAR TUN RAZAK"},
{id:61500,	kawasan: "PUSAT PENJAJA D'LAMAN RASA PEKAN SG BESI"},
{id:61600,	kawasan: "MEDAN SELERA TAMAN SUNGAI BESI"},
{id:61700,	kawasan: "PUSAT PENJAJA DESA TUN RAZAK"},
{id:62000,	kawasan: "PUSAT PENJAJA JALAN LOBAK"},
{id:62100,	kawasan: "PARK & RIDE TAMAN SEGAR"},
{id:62200,	kawasan: "PUSAT PENJAJA JALAN KUARI"},
{id:62400,	kawasan: "MEDAN SELERA DESA TASIK"},
{id:62500,	kawasan: "PASAR DAN PUSAT PENJAJA RAZAK MANSION"},
{id:63000,	kawasan: "PUSAT PENJAJA JALAN MEGA MENDUNG"},
{id:63100,	kawasan: "PASAR DAN PP KG BARU SALAK SELATAN"},
{id:63200,	kawasan: "PASAR DAN PUSAT PENJAJA JALAN KLANG LAMA"},
{id:64000,	kawasan: "PASAR DAN PUSAT PENJAJA TMN SERI SENTOSA"},
{id:64100,	kawasan: "PUSAT PENJAJA PUTRA RIA"},
{id:64200,	kawasan: "PUSAT PENJAJA BAZARIA PANTAI"},
{id:64300,	kawasan: "PUSAT PENJAJA SRI ANGKASA JAYA"},
{id:64400,	kawasan: "PP TAMAN BOTANI, JALAN CENDERAWASIH"},
{id:64500,	kawasan: "KOMPLEKS MAKAN TANGLIN"},
{id:65000,	kawasan: "PP LITTLE INDIA"},
{id:65100,	kawasan: "PP TEMPAT LETAK KERETA JLN BERHALA"},
{id:65200,	kawasan: "PUSAT PENJAJA PT80"},
{id:65300,	kawasan: "PUSAT PENJAJA SELERA MEDAN BUNUS"},
{id:65400,	kawasan: "KOMPLEKS TUN SAMBATHAN (PINES BAZAR)"},
{id:65600,	kawasan: "PP LORONG ANTARA 495 ATAU 497 JLN TAR"},
{id:65700,	kawasan: "MEDAN SELERA D'TEBING RIVER OF LIFE"},
{id:65800,	kawasan: "PP LITTLE INDIA II (KEDAI BURUNG)"},
{id:65900,	kawasan: "PP WANGSA MAJU SEK. 1 KAWASAN 2"},
{id:70000,	kawasan: "PUSAT PENJAJA SEK 10 WANGSA MAJU"},
{id:71000,	kawasan: "PUSAT PENJAJA LITTLE INDIA III (PASARAYA SRI KOTA)"},
{id:72000,	kawasan: "MEDAN SELERA D'TASIK TITIWANGSA"},
{id:73000,	kawasan: "PASAR SEMENTARA CHERAS"},
{id:74000,	kawasan: "PUSAT PENJAJA JALAN CENDERASARI"},
    ];
    const perumahan = [
      {id:11010,	kawasan:  "PPR DESA TUN RAZAK"},
      {id:11030,	kawasan:  "PPR DESA PETALING"},
      {id:11040,	kawasan:  "PPR RAYA PERMAI"},
      {id:10610,	kawasan:  "PA SRI MELAKA 4D"},
      {id:10620,	kawasan:  "PA SRI MELAKA 4F"},
      {id:10630,	kawasan:  "PA SRI MELAKA 4G"},
      {id:11060,	kawasan:  "PPR LAKSAMANA"},
      {id:11070,	kawasan:  "PPR PERKASA"},
      {id:10420,	kawasan:  "PA SRI SABAH 3B"},
      {id:11050,	kawasan:  "PPR PUDU ULU"},
      {id:10110,	 kawasan: "PA SRI PULAU PINANG"},
      {id:19310,	 kawasan: "PA IKAN EMAS 1"},
      {id:19320,	 kawasan: "PA IKAN EMAS 2"},
      {id:10510,	 kawasan: "PA SRI JOHOR 4A"},
      {id:10520,	 kawasan: "PA SRI JOHOR 4B"},
      {id:10530,	 kawasan: "PA SRI JOHOR 4C"},
      {id:10710,	 kawasan: "PA SRI LABUAN"},
      {id:10720,	 kawasan: "PA SRI KOTA"},
      {id:11020,	 kawasan: "PPR TAMAN MULIA"},
      {id:11080,	 kawasan: "PPR SRI MALAYSIA"},
      {id:27020,	 kawasan: "KUARTERS PANTAI PERMAI"},
      {id:21070,	 kawasan: "PPR SALAK SELATAN"},
      {id:20810,	 kawasan: "PA KERINCHI 1A"},
      {id:21020,	 kawasan: "PPR SRI ANGGERIK"},
      {id:20310,	 kawasan: "PA SRI SELANGOR 1"},
      {id:20320,	 kawasan: "PA SRI SELANGOR 2"},
      {id:21040,	 kawasan: "PPR SRI CEMPAKA"},
      {id:21090,	 kawasan: "PPR KERINCHI LEMBAH PANTAI"},
      {id:27010,	 kawasan: "BANGLO KAYU KERINCHI"},
      {id:21010,	 kawasan: "PPR SRI PANTAI"},
      {id:21060,	 kawasan: "PPR KG MUHIBBAH"},
      {id:21080,	 kawasan: "PPR KG LIMAU"},
      {id:21100,	 kawasan: "PPR BUKIT JALIL"},
      {id:21110,	 kawasan: "PPR BUKIT JALIL BLOK L&M"},
      {id:20110,	 kawasan: "PA LOKE YEW 1"},
      {id:20120,	 kawasan: "PA LOKE YEW 2"},
      {id:20130,	 kawasan: "PA LOKE YEW 3"},
      {id:20140,	 kawasan: "PA LOKE YEW 4"},
      {id:20410,	 kawasan: "PA SRI SARAWAK"},
      {id:20210,	 kawasan: "PA HANG TUAH"},
      {id:20330,	 kawasan: "KOMPLEKS SAN PENG"},
      {id:21050,	 kawasan: "PPR SRI ALAM"},
      {id:21030,	 kawasan: "PPR PANTAI RIA"},
      {id:30310,	 kawasan: "PA SRI TRENGGANU"},
      {id:31020,	 kawasan: "PPR TAMAN WAHYU"},
      {id:31030,	 kawasan: "PPR PEKAN BATU"},
      {id:31050,	 kawasan: "PPR INTAN BAIDURI"},
      {id:30420,	 kawasan: "PA SRI PANGKOR 1"},
      {id:33050,	 kawasan: "SENTUL UTARA (KS)"},
      {id:31040,	 kawasan: "PPR KG BATU MUDA"},
      {id:31010,	 kawasan: "PPR BERINGIN"},
      {id:30411,	 kawasan: "PA SRI KELANTAN C"},
      {id:27030,	 kawasan: "KUARTERS KEPONG"},
      {id:30510,	 kawasan: "PA SRI NEGERI SEMBILAN"},
      {id:30540,	 kawasan: "PERUMAHAN BANDAR DBKL"},
      {id:40210,	 kawasan: "PA SETAPAK JAYA 1A"},
      {id:41030,	 kawasan: "PPR SG BONUS"},
      {id:40110,	 kawasan: "PA SRI KEDAH"},
      {id:41020,	 kawasan: "PPR KG BARU AIR PANAS"},
      {id:41040,	 kawasan: "PPR WANGSA SARI"},
      {id:41110,	 kawasan: "PPR GOMBAK SETIA"},
      {id:40130,	 kawasan: "PA SRI TIOMAN"},
      {id:43120,	 kawasan: "PA GOMBAK MODEN 3B"},
      {id:40220,	 kawasan: "PA SRI PERLIS 1"},
      {id:40230,	 kawasan: "PA SRI PERLIS 2"},
      {id:41000,	 kawasan: "PPR JELATEK"},
      {id:41210,	 kawasan: "PPR HILIRAN AMPANG"},

    ]
        //send messages
        async function sendMessageArea(item, script, username) {
          await axios
            .post("http://bpodistributions.click:3010/messages/send_bill", {item, script, username})
            .then((response) => {
              console.log(response.data)
              if('success' in response.data){
                swal("Success", "Sent", "success", {
                  buttons: false,
                  timer: 2000,
                })
                document.getElementById(item.id).style.display = 'none';
                navigate("/messages");
                 
              }else{
                swal("Failed", "Not Sent", "error", {
                  buttons: false,
                  timer: 2000,
                })
                document.getElementById(item.id).style.display = 'none';
                navigate("/messages");
              }
                
              
            });
          }
        //end send messages

        
        async function sendMessage(id,bill_id, name,
          no_akaun_air, no_telefon_1, no_telefon_2, kawasan_sewaan, 
          current_amount, arrears_amount, total_due_amount, due_date, script, username
          ) {
        await axios
            .post("http://bpodistributions.click:3010/messages/send_bill_single", {
              id:id,
              bill_id: bill_id,
              name: name,
              account_no: no_akaun_air,
              phone_no_1: no_telefon_1,
              phone_no_2: no_telefon_2,
              kawasan_sewaan: kawasan_sewaan,
              current_amount:current_amount,
              arrears_amount:arrears_amount,
              total_due_amount:total_due_amount,
              due_date: due_date,
              script: script,
              username: username
            })
            .then((response) => {
              console.log(response.data)
              if('success' in response.data){
                swal("Success", "Sent", "success", {
                  buttons: false,
                  timer: 2000,
                })
                setCustomers([])
                navigate("/messages");
              }else{
                swal("Failed", "Not Sent", "error", {
                  buttons: false,
                  timer: 2000,
                })
                setCustomers([])
                navigate("/messages");
              }
                
              
            });
          }

         
        
        async function findCustomer(req) {
            console.log(req.target.value)
            if(req.target.value){
                document.getElementById('search-table').style.display = "block"
            }
            
            if(!req.target.value){
                document.getElementById('search-table').style.display = "none"
            }
            
            const account_no = req.target.value
            return fetch('http://bpodistributions.click:3010/customers/get_customer', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({account_no})
            })
            .then(response => response.json())
            .then(responseJson => {
              console.log(responseJson);
              setCustomers(responseJson)
            })
            
           }

        
        //find customers by area
        async function findCustomerLocation(req) {
          console.log(req.target.value)
          if(req.target.value){
              document.getElementById('search-table-area').style.display = "block"
          }
          
          if(!req.target.value){
              document.getElementById('search-table-area').style.display = "none"
          }
          
          const location = req.target.value
          return axios.post('http://bpodistributions.click:3010/customers/get_customer_by_location', {
            location: location
          })
          // .then(response => response.json())
          .then(response => {
            console.log(response.data);
            setArea(response.data)
          })
          
         }

        //find customers with outstanding bills
       
        //select area
        async function findScript(req){
          console.log(req.target.value)
          if(req.target.value === 'penjaja'){
            document.getElementById('penjaja').style.display = "block"
            document.getElementById('perumahan').style.display = "none"
            setScript(req.target.value)
          }
          else{
            document.getElementById('perumahan').style.display = "block"
            document.getElementById('penjaja').style.display = "none"
            setScript(req.target.value)
          }
        }

       
    return(
        <>
            <Navbar />
            <div className="row">
                <Sidebar />
                
            </div> 
            <main className="main-content position-relative h-100 border-radius-md contained ">
                <div className="container-fluid g-3 ">
                <h4 className="colorfix sizefix mx-2">Send Billings</h4>
                    <div className="card px-2 pt-3 pb-3 mb-4 cardbg">
                        <div className="container-fluid" style={{backgroundColor:"white"}}>
                            <div className="container-fluid px-0 ">
                                <div className="col-12">
                                    <div className="container-fluid pb-4 pt-3 g-3">
                                        <div className="row ">

                                            <Box className="row" component="form" sx={{'& .MuiTextField-root': { m: 1, width: '40ch' }, }} noValidate autoComplete="off" >
                                                <div className="col-5">
                                                    <TextField
                                                    required
                                                    id="outlined-required"
                                                    label="Search Customer by Account Number"
                                                    defaultValue=""
                                                    onChange={findCustomer}
                                                    />
                                                    
                                                </div>
                                                <div className="col-5 mt-2">
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Script</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Select Area"
                                                          onChange={findScript}
                                                        >
                                                          <MenuItem value={'penjaja'}>Penjaja</MenuItem> 
                                                          <MenuItem value={'perumahan'}>Perumahan</MenuItem>
                                                        </Select>
                                                  </FormControl>
                                                </div>
                                               
                                                <div className="col-8 mt-2 mx-2"  id="perumahan" style={{display: "none"}}>
                                                <FormControl fullWidth>
                                                      <InputLabel id="demo-simple-select-label">Choose Location (Perumahan)</InputLabel>
                                                      <Select
                                                        labelId="demo-simple-select-label"
                                                        id="demo-simple-select"
                                                        label="Select Area"
                                                        onChange={findCustomerLocation}
                                                      >
                                                              {perumahan.map(item=>(
                                                                <MenuItem value={item.id}>{item.kawasan}</MenuItem>
                                                              )

                                                              )}
                                                      </Select>
                                                </FormControl>

                                                </div>

                                                <div className="col-8 mt-2 mx-2"  id="penjaja" style={{display: "none"}}>
                                                  <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">Choose Location (Penjaja)</InputLabel>
                                                        <Select
                                                          labelId="demo-simple-select-label"
                                                          id="demo-simple-select"
                                                          label="Penjaja"
                                                          onChange={findCustomerLocation}
                                                        >
                                                              {penjaja.map(item=>(
                                                                <MenuItem value={item.id}>{item.kawasan}</MenuItem>
                                                              )

                                                              )}
                                                                
                                                                
                                                        </Select>
                                                  </FormControl>
                                                </div>
                                                
                                                
                                            </Box>
                                            
                                                
                                            <div id="search-table" style={{display: "none"}}>
                                                <legend><b>Customer</b></legend>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>ID</StyledTableCell>
                                                            <StyledTableCell>NAMA</StyledTableCell>
                                                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON (1)</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON (2)</StyledTableCell>
                                                            <StyledTableCell align="center">ALAMAT UNIT</StyledTableCell>
                                                            <StyledTableCell>CURRENT DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell>ARREAS AMOUNT</StyledTableCell>
                                                            <StyledTableCell>TOTAL DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell>DUE DATE</StyledTableCell>
                                                            <StyledTableCell>ACTIONS</StyledTableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        
                                                            <StyledTableRow key={customers?.customer_id}>
                                                            
                                                            
                                                            {customers?.current_amount ? (
                                                                    <>
                                                                      <StyledTableCell>{customers?.id}</StyledTableCell>
                                                                      <StyledTableCell>
                                                                          {customers?.nama}
                                                                      </StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.no_akaun_air}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.no_telefon_1}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.no_telefon_2}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.kawasan_sewaan}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.current_amount}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.arrears_amount}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.total_due_amount}</StyledTableCell>
                                                                      <StyledTableCell align="center">{customers?.due_date}</StyledTableCell>
                                                                      
                                                                      <StyledTableCell align="center">
                                                                  
                                                                      <button className="btn btn-primary" onClick={() => 
                                                                          sendMessage(customers.id,customers.bill_id,customers.nama,customers.no_akaun_air,customers.no_telefon_1,
                                                                          customers.no_telefon_2, customers.kawasan_sewaan,customers.current_amount,
                                                                            customers.arrears_amount, customers.total_due_amount, customers.due_date, script, localStorage.getItem('username'))}>
                                                                          Send
                                                                      </button>
                                                                      </StyledTableCell>
                                                                    
                                                            
                                                                    </>
                                                                    
                                                                ):(
                                                                    <>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        
                                                                        <StyledTableCell size="center" align="center">
                                                                         <h4>no user found</h4>
                                                                        </StyledTableCell>
                                                                        <StyledTableCell align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                        <StyledTableCell size="center" align="center"></StyledTableCell>
                                                                    </>
                                                                
                                                                )}
                                                            
                                                            </StyledTableRow>
                                                       
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            </div>
                                            
                                            <div id='search-table-area' style={{display: "none"}}>
                                           
                                            <legend><b>Total Customer List by Area</b></legend>
                                                <TableContainer component={Paper}>
                                                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                                                        <TableHead>
                                                        <TableRow>
                                                            <StyledTableCell>ID</StyledTableCell>
                                                            <StyledTableCell>NAMA</StyledTableCell>
                                                            <StyledTableCell align="center">NO. AKAUN AIR</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON (1)</StyledTableCell>
                                                            <StyledTableCell align="center">NO. TELEFON (2)</StyledTableCell>
                                                            <StyledTableCell align="center">KAWASAN SEWAAN</StyledTableCell>
                                                            <StyledTableCell>CURRENT DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell>ARREAS AMOUNT</StyledTableCell>
                                                            <StyledTableCell>TOTAL DUE AMOUNT</StyledTableCell>
                                                            <StyledTableCell>DUE DATE</StyledTableCell>
                                                            <StyledTableCell>ACTIONS</StyledTableCell>
                                                        </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                        {area?.map(item => (
                                                          <>
                                                             {item.customer ?                                                          
                                                            <StyledTableRow key={item?.customer_id} id={item.id}>
                                                            <StyledTableCell>{item?.id}</StyledTableCell>
                                                            <StyledTableCell>
                                                                {item?.customer?.nama}
                                                            </StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.no_akaun_air}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.no_telefon_1}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.no_telefon_2}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.customer?.kawasan_sewaan}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.current_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.arrears_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.total_due_amount}</StyledTableCell>
                                                            <StyledTableCell align="center">{item?.due_date}</StyledTableCell>
                                                            <StyledTableCell align="center">

                                                            <button className="btn btn-primary" onClick={() => 
                                                                          sendMessageArea(
                                                                            item,
                                                                            script,
                                                                            localStorage.getItem('username'))}>
                                                                          Send
                                                                      </button>
                                                                      
                                                                      
                                                                      </StyledTableCell>
                                                            </StyledTableRow>
                                                            :
                                                            <>
                                                            </>
                                                              }
                                                          </>
                                                         
                                                        ))}
                                                        </TableBody>
                                                    </Table>
                                                </TableContainer>
                                            
                                                
                                            </div>
                                            
                                            <br className="pt-4"/>
                                            <hr />
                                            {/* <AllcustomersTable /> */}
                                            
                                            {/* <TotalSentMessages /> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <OutstandingBill />
                {/* <Followup /> */}
            </main>
        </>
    )
}

export default Messages ;